import React from "react"
import { Link } from "gatsby"

const JobCats = () => {
  return (
    <div className="flex items-center flex-wrap mb-8 md:mb-16">
      <Link
        to="/jobs"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        View All
      </Link>

      <Link
        to="/jobs/frontend"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        Frontend
      </Link>

      <Link
        to="/jobs/backend"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        Backend
      </Link>

      <Link
        to="/jobs/mobile"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        Mobile
      </Link>

      <Link
        to="/jobs/infrastructure"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        Infrastructure
      </Link>

      <Link
        to="/jobs/data"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        Data
      </Link>

      <Link
        to="/jobs/qa"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        QA
      </Link>

      <Link
        to="/jobs/leadership"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        Leadership
      </Link>

      <Link
        to="/jobs/security"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        Security
      </Link>

      <Link
        to="/jobs/dev-rel"
        className="text-white xl:text-gray-400 bg-gray-700 xl:bg-gray-900 bg-opacity-40 xl:hover:bg-gray-700 xl:hover:text-white px-4 py-2 mr-3 mt-3 rounded-3xl text-xs md:text-sm font-medium uppercase"
        activeClassName="bg-gray-700"
      >
        Dev Rel
      </Link>
    </div>
  )
}

export default JobCats