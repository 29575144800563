import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledLogoContainer = styled.div`
  width: 80px;
  height: 80px;

  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
`

const JobCardNew = ({ jobId, jobLink, jobTitle, compLogo, compLogoAlt, compName, jobType, remoteLocation, minSalary, maxSalary, jobPostDate, featured }) => {
  return (
    <>
      <Link to={jobLink ? `/job/${jobId}/remote-${jobLink}` : '/jobs'}>
        <div className={`${featured ? "border-2 border-gray-500 shadow-lg" : "border border-site-color-brand-3"} group relative bg-site-color-brand-1 hover:bg-site-color-brand-3 cursor-pointer flex flex-col lg:flex-row lg:items-center mt-8 p-6 rounded-2xl`}>
          {compLogo
            ?
            <StyledLogoContainer className="rounded-full flex items-center justify-center flex-shrink-0">
              <GatsbyImage
                alt={compLogoAlt}
                image={compLogo}
                formats={["auto", "webp", "avif"]}
                className="rounded-full mx-auto"
              />
            </StyledLogoContainer>
            :
            null
          }
          <div className="mt-4 lg:mt-0 lg:ml-6">
          <p className="text-lg text-gray-300 mb-1">{compName ? compName : "Company Name"}</p>
            <h2 className="text-gray-200 group-hover:text-gray-100 text-2xl md:text-3xl font-bold">{jobTitle ? jobTitle : "Job Title"}</h2>
            <div className="mt-2">
              <h3 className="text-gray-400">{jobType ? jobType : "Full-time"} | {remoteLocation ? remoteLocation : "Worldwide"} {minSalary ? `| ${minSalary}-${maxSalary}` : null}</h3>
            </div>
          </div>
          <div className="absolute right-0 flex items-center">
            <div className="mr-6">
              <p className="text-gray-300 font-semibold">{jobPostDate ? jobPostDate : "1 Jan 2121"}</p>
            </div>
            {featured
              ?
              <div>
                <button className="bg-yellow-400 text-yellow-900 text-sm md:text-base px-3 py-1 md:px-7 md:py-2 mr-6 font-semibold rounded-md">
                  Featured
                </button>
              </div>
              :
              null
            }
          </div>
        </div>
      </Link>
    </>
  )
}

export default JobCardNew
